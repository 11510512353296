import * as React from "react"
import PropTypes from "prop-types"
import Footer from "../footer"
import "../../helpers/fonts.css"
import GlobalStyle from "../../helpers/global-styles"
import { MainWrapper, Content, CenterWrapper } from "./index.style"

const DefaultLayout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <MainWrapper>
        <Content>
          <CenterWrapper>{children}</CenterWrapper>
        </Content>
        <Footer />
      </MainWrapper>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default DefaultLayout
