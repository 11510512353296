import * as React from "react"
import { FooterLeftCol, FooterLogoWrap, SocialMediaWrapper } from "./index.style"
import { Logo, InstagramIcon, FaceBookIcon } from "../../icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const FooterLeftColumn = () => {
  return (
    <FooterLeftCol>
      <FooterLogoWrap>
        <AniLink fade duration={2} to="/">
          <Logo />
        </AniLink>
      </FooterLogoWrap>
      <SocialMediaWrapper>
        <a
          href="https://www.instagram.com/rubychelseanyc/"
          className="instagram-link"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.facebook.com/RubyChelseaNYC"
          target="_blank"
          rel="noreferrer"
        >
          <FaceBookIcon />
        </a>
      </SocialMediaWrapper>
    </FooterLeftCol>
  )
}
export default FooterLeftColumn
