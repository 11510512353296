import * as React from "react"
import { BackdArrow } from "../../icons"
import { navigate } from "gatsby"
import { BackBtn, MenuWrap } from "./index.style"
import { Link } from "gatsby"

const LegalSidebar = () => {
  return (
    <>
      <BackBtn onClick={() => navigate(-1)}>
        <BackdArrow />
        Back
      </BackBtn>
      <MenuWrap>
        <Link activeClassName="active" to="/disclaimer">
          DISCLAIMER
        </Link>
        <Link activeClassName="active" to="/privacy-policy">
          PRIVACY POLICY
        </Link>
        <Link activeClassName="active" to="/terms-of-use-agreement">
          TERMS OF USE AGREEMENT
        </Link>
      </MenuWrap>
    </>
  )
}

export default LegalSidebar
