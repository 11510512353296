import * as React from "react"
import useWindowSize from "../../hooks/use-window-size"
import FooterLeftColumn from "./footer-left-column"
import FooterRightColumn from "./footer-right-column"
import { CenterWrapper, FooterWrap } from "./index.style"
import MobileFooter from "./mobile-footer"

const Footer = () => {
  const windowSize = useWindowSize()
  return (
    <>
      {windowSize.width < 900 ? (
        <MobileFooter />
      ) : (
        <FooterWrap>
          {/* <CenterWrapper> */}
          <FooterLeftColumn />
          <FooterRightColumn />
          {/* </CenterWrapper> */}
        </FooterWrap>
      )}
    </>
  )
}

export default Footer
