import styled from "styled-components"
import { mediaMin, mediaMax } from "../../helpers/media-queries"
import { calculateResponsiveSize } from "../../helpers/calculate-responsive-size"

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: var(--main-bg-color);
`

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px 50px;
  box-sizing: border-box;
  ${mediaMax("phoneXL")} {
    padding: 68px 28px;
  }
  ${mediaMin("desktopXLarge")} {
    max-width: ${calculateResponsiveSize(1280)};
    padding: ${calculateResponsiveSize(60)} ${calculateResponsiveSize(50)};
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`
