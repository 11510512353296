import * as React from "react"
import { Paragraph } from "./index.style"

const Disclaimer = () => {
  return (
    <>
      <Paragraph>
        Renderings and interior decorations, finishes, fixtures, appliances, and
        furnishings are provided for the purposes of illustration only. Artist
        and computer renderings reflect the planned scale and spirit of the
        building, but 241 W. 28th St. Owner LLC (“28th Street Owner”) makes no
        representation or warranty based on the images contained on this
        website. All dimensions, including ceiling heights, are approximate and
        subject to normal construction variances and tolerances. 28th Street
        Owner makes no representation regarding views from any windows and/or
        exterior spaces and how they may be affected by future construction in
        the neighborhood surrounding the building.
      </Paragraph>

      <Paragraph>Renderings, branding and creative by DBOX.</Paragraph>
    </>
  )
}

export default Disclaimer
