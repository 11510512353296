import * as React from "react"
import { MagIcon, DeIcon, CookFoxIcon, HousingIcon } from "../../icons"
import {
  FooterRightCol,
  FooterLink,
  Col,
  LogoBlock,
  HousingWrap,
} from "./index.style"
import { Link } from "gatsby"

const FooterRightColumn = () => {
  return (
    <FooterRightCol>
      <Col className="first-column">
        <FooterLink
          className="adress"
          href="https://www.google.com/maps/place/243+W+28th+St,+New+York,+NY+10001,+%D0%A1%D0%A8%D0%90/@40.7106737,-74.0182606,15z/data=!4m5!3m4!1s0x89c259afe38a78b3:0x301cf1940d4ee609!8m2!3d40.7481825!4d-73.9950951"
          target="_blank"
        >
          243 W 28th Street <br />
          New York, NY 10001
        </FooterLink>
        <Link className="legal-link-desktop" to="/disclaimer">
          Legal
        </Link>
      </Col>

      <Col>
        {/* <FooterLink href="tel:123-456-789">123-456-789</FooterLink> */}
        <FooterLink
          className="mail-link"
          href="mailto:leasing@rubychelsea.com"
          mb="30px"
        >
          212 551 RUBY
          <br />
          leasing@rubychelsea.com
        </FooterLink>
        <FooterLink
          href="/nys-housing-and-anti-discrimination-notice_06.2024.pdf "
          target="_blank"
        >
          Fair Housing Notice
        </FooterLink>
        <FooterLink
          href="/Notice_ReasonableAccommodations_Form.pdf"
          target="_blank"
        >
          Notice for Reasonable Accomodations
        </FooterLink>
        <Link className="legal-link-mob" to="/disclaimer">
          Legal
        </Link>
      </Col>
      <Col>
        <LogoBlock>
          <FooterLink className="mag-icon" as="div">
            <MagIcon />
          </FooterLink>
          <FooterLink className="de-icon" as="div">
            <DeIcon />
          </FooterLink>
          <FooterLink className="cook-fox-icon" as="div">
            <CookFoxIcon />
          </FooterLink>
        </LogoBlock>
        <HousingWrap>
          <FooterLink className="housing" as="div">
            <HousingIcon />
          </FooterLink>
        </HousingWrap>
      </Col>
    </FooterRightCol>
  )
}
export default FooterRightColumn
