import * as React from "react"
import LegalSidebar from "./legal-sidebar"
import { LegalWrap, Col } from "./index.style"

const LegalLayout = ({ children }) => {
  return (
    <LegalWrap>
      <Col>
        <LegalSidebar />
      </Col>
      <Col className="content">{children}</Col>
    </LegalWrap>
  )
}

export default LegalLayout
