import styled from "styled-components"
import { calculateResponsiveSize } from "../../helpers/calculate-responsive-size"
import { mediaMin } from "../../helpers/media-queries"
export const Paragraph = styled.p`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  word-break: break-word;
  &:first-child {
    margin-top: 20px;
  }
  ${mediaMin("desktopXLarge")} {
    margin-bottom: ${calculateResponsiveSize(16)};
    font-size: ${calculateResponsiveSize(10)};
    &:first-child {
      margin-top: ${calculateResponsiveSize(20)};
    }
  }
`
