import * as React from "react"
import LegalLayout from "../components/legal-layout"
import Disclaimer from "../components/disclaimer"

import DefaultLayout from "../components/default-layout"
import Seo from "../components/seo"
import Layout from "../components/app-layout"

const DisclaimerPage = () => (
  <Layout>
    <DefaultLayout>
      <LegalLayout>
        <Disclaimer />
      </LegalLayout>
    </DefaultLayout>
  </Layout>
)

export const Head = () => <Seo title="Disclaimer" />

export default DisclaimerPage
