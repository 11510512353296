import React from "react"
import { Logo } from "../ui"
import * as Styled from "./index.style"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {
  CookFoxIcon,
  DeIcon,
  FaceBookIcon,
  HousingIcon,
  InstagramIcon,
  MagIcon
} from "../../icons"
import { Link } from "gatsby"

const MobileFooter = () => {
  return (
    <Styled.MobileFooterWrapper>
      <Styled.MobileLogoBlock>
        <AniLink fade duration={2} to="/">
          <Logo fill="#A1B38B" width={200} height={50} />
        </AniLink>
        <br />
        <Styled.SocialMediaWrapper>
          <a
            href="https://www.instagram.com/rubychelseanyc/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon width={"32"} height={"32"} />
          </a>
          <a
            href="https://www.facebook.com/RubyChelseaNYC"
            target="_blank"
            rel="noreferrer"
          >
            <FaceBookIcon width={"32"} height={"32"} />
          </a>
        </Styled.SocialMediaWrapper>
      </Styled.MobileLogoBlock>
      <Styled.InfoBlock>
        <br />
        <Styled.FooterLink
          href="https://www.google.com/maps/place/243+W+28th+St,+New+York,+NY+10001,+%D0%A1%D0%A8%D0%90/@40.7106737,-74.0182606,15z/data=!4m5!3m4!1s0x89c259afe38a78b3:0x301cf1940d4ee609!8m2!3d40.7481825!4d-73.9950951"
          target="_blank"
        >
          243 W 28th Street
          <br />
          New York, NY 10001
        </Styled.FooterLink>
        <br />
        <a href="tel:2125517829">212 551 RUBY</a>
        <Styled.FooterLink href="mailto:leasing@rubychelsea.com" mb="30px">
          leasing@rubychelsea.com
        </Styled.FooterLink>
        <Styled.FooterLink
          href="/nys-housing-and-anti-discrimination-notice_06.2024.pdf "
          target="_blank"
        >
          Fair Housing Notice
        </Styled.FooterLink>
        <Styled.FooterLink
          href="/Notice_ReasonableAccommodations_Form.pdf"
          target="_blank"
        >
          Notice for Reasonable Accomodations
        </Styled.FooterLink>
        <br />

        <Link to="/disclaimer">Legal</Link>
        <br />
      </Styled.InfoBlock>
      <Styled.LastBlock>
        <Styled.FooterLink className="mag-icon" as="div">
          <MagIcon />
        </Styled.FooterLink>
        <Styled.FooterLink className="de-icon" as="div">
          <DeIcon />
        </Styled.FooterLink>
        <Styled.FooterLink className="cook-fox-icon" as="div">
          <CookFoxIcon />
        </Styled.FooterLink>
      </Styled.LastBlock>
      <Styled.FooterLink className="house-icon">
        <HousingIcon />
      </Styled.FooterLink>
    </Styled.MobileFooterWrapper>
  )
}

export default MobileFooter
