import styled from "styled-components"
import { mediaMax, mediaMin } from "../../helpers/media-queries"
import { calculateResponsiveSize } from "../../helpers/calculate-responsive-size"

export const MenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding-right: 20px;
  ${mediaMax("tablet")} {
    width: 100%;
    align-items: center;
    margin: 40px auto;
  }
  a {
    display: block;
    margin-bottom: 20px;
    color: inherit;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
  ${mediaMin("desktopXLarge")} {
    padding-right: ${calculateResponsiveSize(20)};
    a {
      margin-bottom: ${calculateResponsiveSize(20)};
      font-size: ${calculateResponsiveSize(15)};
      ${calculateResponsiveSize(25)};
    }
  }
`
export const BackBtn = styled.button`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 175%;
  background: transparent;
  border: 0;
  cursor: pointer;
  width: fit-content;
  svg {
    margin-right: 12px;
  }
  ${mediaMin("desktopXLarge")} {
    margin-top:  ${calculateResponsiveSize(-40)};
    margin-bottom:  ${calculateResponsiveSize(40)};
    font-size:  ${calculateResponsiveSize(15)};
    svg {
    margin-right: ${calculateResponsiveSize(12)};
    width: ${calculateResponsiveSize(11)};
    height: ${calculateResponsiveSize(13)};
  }
  }
  ${mediaMax("phoneXL")} {
    display: none;
  }
`

export const LegalWrap = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaMax("tablet")} {
    flex-direction: column;
  }
  animation: pageFading 1s;
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  box-sizing: border-box;

  &.content {
    padding-bottom: 30px;
    overflow: auto;
    max-height: calc(100vh - 260px); //footer + padding top
    ${mediaMin("desktopXLarge")} {
      padding-right: ${calculateResponsiveSize(30)};
      padding-bottom: ${calculateResponsiveSize(30)};
    }
    /* Firefox */
    // & {
    //   scrollbar-width: thin;
    //   scrollbar-color: var(--main-color) rgba(0, 0, 0, 0.1);
    // }

    // /* Chrome, Edge, and Safari */
    // &::-webkit-scrollbar {
    //   width: 4px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: rgba(0, 0, 0, 0.1);
    //   opacity: 0.5;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: var(--main-color);
    //   border-radius: 0px;
    // }
  }
  &:first-child {
    min-width: 540px;
    ${mediaMin("desktopXLarge")} {
      min-width: ${calculateResponsiveSize(540)};
    }
  }
  ${mediaMax("desktopSmall")} {
    &:first-child {
      min-width: fit-content;
    }
  }
`
